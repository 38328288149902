import React, { useState, useEffect } from 'react';
import {LOCAL_STORAGE_TOKEN} from '../shared/consts'
import { useDispatch, useSelector } from 'react-redux';
import {
    confirmLogin
} from '../redux/actions/index'
import { Ring } from 'react-spinners-css';

import './Result.css'



const Result = (props) =>  {

    const dispatch = useDispatch();

    const [ loading, setLoading ] =  useState(true);


    useEffect(() => {
        if(localStorage.getItem(LOCAL_STORAGE_TOKEN)){
            console.log("dispatch(confirmLogin());")
            dispatch(confirmLogin());
        }

    }, [localStorage.getItem(LOCAL_STORAGE_TOKEN)]);


    const urlParams = new URLSearchParams(window.location.search);

    const state = urlParams.get('state');

    let title = ""
    let description = ""

    let token = localStorage.getItem(LOCAL_STORAGE_TOKEN)
    token = token.replace(/['"]+/g, '')


    if(state === "true"){

        title += props.lang === "EN" ? "You will get a Visa" : "Du bekommst ein Visa"
        description += props.lang === "EN" ? "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. " 
                                        : 
                                        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. "

    }else{
        title += props.lang === "EN" ? "You won't get a Visa" : "Du bekommst kein Visa"
        description += props.lang === "EN" ? "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. " 
                                        : 
                                        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. "

    }



    setTimeout(function(){ setLoading(false)}, 1500);



    return (
        <>
        {loading ? (
            <>
            <div className="ringContainer">
            <Ring color="#6F96FF"></Ring>
            </div>
            <div style={{minHeight:"400px"}}></div>
            </>
        ) : (
            <div className="auth-wrapper align-self-center mt-5">
            <div className="auth-inner result">
                <form>
                    <h3 className="mb-5 text-center">{title}</h3>
                    <p className="text-center mt-5 mb-5 pb-5">
                    {description}
                    <br></br>
                    <br></br>
                    <a style={{width:"100%"}} href={`https://app2.immigration-check.de/validate-user/${token}`} >{props.lang === "EN" ? "Book a Service" : "Buche einen Service"}</a>
                    </p>
                    
        
                    <button type="submit" className="btn btn-primary btn-block blue-btn mt-5">{props.lang === "EN" ? "Download PDF Instruction" : "PDF- Anleitung erstellen"}</button>
                </form>
            </div>
        </div>
        )
    }
        
        </>
    );
}

export default Result;