import React, { useState }  from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    Link,
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute'
import Auth from '../containers/Auth/Auth'
import Signin from '../containers/Signin'
import Register from '../containers/Register'
import ResetPassword from '../containers/ResetPassword'
import NewPassword from '../containers/NewPassword'
import ConfirmationPassword from '../containers/ConfirmationPassword'
import CheckEmail from '../containers/CheckEmail'
import QuestionBeta from '../containers/QuestionBeta'
import Result from '../containers/Result'
import SelectCountry from '../containers/SelectCountry'



function AllRoutes() {

    return (
        <Switch >
            <PrivateRoute exact path="/de" component={() => <QuestionBeta lang={"DE"} ></QuestionBeta>} />
            <PrivateRoute exact path="/de/question" component={() => <QuestionBeta lang={"DE"} ></QuestionBeta>} />
            <PrivateRoute path="/de/result" component={(props) => <Result lang={"DE"} {... props}></Result>} />

            <PrivateRoute exact path="/en" component={() => <QuestionBeta lang={"EN"} ></QuestionBeta>} />
            <PrivateRoute exact path="/en/question" component={() => <QuestionBeta lang={"EN"} ></QuestionBeta>} />
            <PrivateRoute path="/en/result" component={(props) => <Result lang={"EN"} {... props}></Result>} />

            <Route path="/select" component={() => <SelectCountry lang={"DE"}></SelectCountry>} />

            <Route path="/de/signin" component={() => <Signin lang={"DE"}></Signin>} />
            <Route path="/de/register" component={() => <Register lang={"DE"}></Register>} />
            <Route path="/de/resetpassword" component={() => <ResetPassword lang={"DE"}></ResetPassword>} />
            <Route path="/de/newpassword" component={() => <NewPassword lang={"DE"}></NewPassword>} />
            <Route path="/de/confirmationpassword" component={() => <ConfirmationPassword lang={"DE"}></ConfirmationPassword>} />
            <Route path="/de/checkemail" component={() => <CheckEmail lang={"DE"}></CheckEmail>} />


            <Route path="/en/signin" component={() => <Signin lang={"EN"}></Signin>} />
            <Route path="/en/register" component={() => <Register lang={"EN"}></Register>} />
            <Route path="/en/resetpassword" component={() => <ResetPassword lang={"EN"}></ResetPassword>} />
            <Route path="/en/newpassword" component={() => <NewPassword lang={"EN"}></NewPassword>} />
            <Route path="/en/confirmationpassword" component={() => <ConfirmationPassword lang={"EN"}></ConfirmationPassword>} />
            <Route path="/en/checkemail" component={() => <CheckEmail lang={"EN"}></CheckEmail>} />

            <Redirect from='*' to='/en/signin' />
        </Switch>
    );
};

export default AllRoutes;