import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { signUp, removeAuthError } from '../redux/actions/index'
import {checkValidity} from '../shared/utility'


const Register = props => {

    const { onSignUp, token, error, loading, onRemoveErrorState } = props;


    
    useEffect(()=>{
        onRemoveErrorState()
    }, []) 

    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [password, setPassword] = useState("");

    const [nameIsMissing, setNameIsMissing] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [checkboxIsMissing, setCheckboxIsMissing] = useState(false);


    const clickHandler = (e) => {
        e.preventDefault()
        console.log(userName)

        if(userName.length < 1){
            setNameIsMissing(true)
            return
        }else{
            setNameIsMissing(false)
        }

        const isValidEmail = checkValidity(userEmail, {isEmail: true})

        if(!isValidEmail){
            setInvalidEmail(true)
            return
        }else{
            setInvalidEmail(false)
        }

        const checkbox = document.getElementById('policy-checkbox')

        setCheckboxIsMissing(!checkbox.checked)

        if(!checkbox.checked)return

        onSignUp(userName, userEmail, password)        
    }

    return (
        <>
            {
                token ? (
                    <Redirect to={{ pathname: props.lang === "EN" ? "/en" : "/de"}} />
                ) : (
                        <div className="auth-wrapper align-self-center mt-5">
                            <div className="auth-inner register">
                                <form>
                                    <h3 className="mb-2">{props.lang === "EN" ? "Create an account" : "Account erstellen"}</h3>
                                    <p style={{marginBottom: "1px"}}>{props.lang === "EN" ? "Already have an account?" : "Du hast bereits einen Account?"}</p>
                                    <Link to={props.lang === "EN" ? "/en/signin" : "/de/signin"}>{props.lang === "EN" ? "Sign in" : "Hier anmelden"}</Link>
                                    <div style={{marginBottom: "0px"}} className="form-group mt-4">
                                        <label>{props.lang === "EN" ? "Full Name" : "Gesamter Name"}</label>
                                        <input
                                            type="name"
                                            className="form-control"
                                            value={userName}
                                            onChange={e => {
                                                setUserName(e.target.value);
                                            }}
                                            placeholder=""
                                        />
                                    </div>
                                    {nameIsMissing && <p className="warning-label">{props.lang === "EN" ? "Please provide a name" : "Bitte gebe einen Namen an"}</p>}
                                    <div style={{marginBottom: "0px"}} className="form-group mt-4">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            value={userEmail}
                                            onChange={e => {
                                                setUserEmail(e.target.value);
                                            }}
                                            placeholder=""
                                        />
                                    </div>
                                    {invalidEmail && <p className="warning-label">{props.lang === "EN" ? "Invalid Email" : "Ungültige Email"}</p>}
                                    <div className="form-group mt-4">
                                        <label>{props.lang === "EN" ? "Password" : "Passwort"}</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder=""
                                            value={password}
                                            onChange={e => {
                                                setPassword(e.target.value);
                                            }}
                                        />
                                    </div>
                                    {error && <p className="warning-label">{props.lang === "EN" ? "Something went wrong, please try again!" : "Etwas ist schiefgelaufen, bitte versuche es erneut!"}</p>}
                                    <div class="form-check mt-4 mb-4">
                                        <input id="policy-checkbox" style={{marginTop:"6px"}} class="form-check-input" type="checkbox" value=""/>
                                        <label style={{color: checkboxIsMissing ? "#FF7171" : "#756F86"}} class="form-check-label" for="defaultCheck1">
                                            {props.lang === "EN" ? "By signing up, I agree to the terms." : "Mit der Erstellung eines Kontos stimme ich den Datenschutzbedingungen zu."}
                          </label>
                                    </div>
                                    <button className="btn btn-primary btn-block blue-btn mt-4" onClick={(e) => clickHandler(e)}>{props.lang === "EN" ? "Sign Up" : "Registrieren"}</button>
                                </form>
                            </div>
                        </div>
                    )
            }
        </>
    );
}

const mapStateToProps = state => {

    return {
        token: state.auth.token,
        redirect_path: state.auth.redirect_path,
        error: state.auth.error_code,
        question: state.question.question,
        id_of_answer: state.question.id_of_answer
    };
};

const mapDispatchToProps = dispatch => {

    return {
        onSignUp: (name, email, password) => dispatch(signUp(name, email, password)),
        onRemoveErrorState: () => dispatch(removeAuthError())

    };
};

export default 
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Register)