//AUTHENTICATION ACTION TYPES
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_SIGNOUT = 'AUTH_LOGOUT';
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CONFIRM_PASSWORD = 'CONFIRM_PASSWORD';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const CONFIRM_PASSWORD_FAIL = 'CONFIRM_PASSWORD_FAIL';
export const CONFIRM_LOGIN = 'CONFIRM_LOGIN';
export const REMOVE_ERROR_STATE = 'REMOVE_ERROR_STATE';


export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';


//QUESTION ACTION TYPES
export const QUESTION_START = 'QUESTION_START';
export const QUESTION_SUCCESS = 'QUESTION_SUCCESS';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const QUESTION_FAIL = 'QUESTION_FAIL';
export const SET_QUESTION_REDIRECT_PATH = 'SET_QUESTION_REDIRECT_PATH';