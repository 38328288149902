import React from 'react';
import { Link } from 'react-router-dom';


const ConfirmationPassword = (props) => {

    return (
        <>
            <div className="auth-wrapper align-self-center mt-5">
                <div className="auth-inner confirmation-password">
                    <form>
                        <h3 className="mb-4 text-center">{props.lang === "EN" ? "You've successfully changed your Password" : "Du hast dein Passwort erfolgreich umgeändert"}</h3>
                        <p className="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </p>
                        <Link to={props.lang === "EN" ? "/en/signin" : "/de/signin"}><button type="submit" className="btn btn-primary btn-block blue-btn mt-4">{props.lang === "EN" ? "Back to Sign in" : "Zurück zum Anmelden"}</button></Link>

                    </form>
                </div>
            </div>
        </>
    );
}

export default ConfirmationPassword;