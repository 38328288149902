import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {
    token: null,
    error_code: null,
    error_reset_password : false,
    error_confirm_password : false,
    logout:true,
    loading: false,
    redirect_path: '/'
};

const authStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const authSuccess = (state, action) => {

    return updateObject( state, { 
        token: action.token,
        error_code: null,
        loading: false,
        logout:false,
        redirect_path: "/"
         } );
};

const authFail = (state, action) => {
    return updateObject( state, {
        error_code: action.error_code,
        loading: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { 
        token: null,
        loading:false,
        logout:true,
        redirect_path: "/en/signin"});
};


const resetPassword = (state, action) => {
    console.log("resetPassword")
    return updateObject(state, { 
        token: null,
        loading:false,
        redirect_path: "/en/checkemail"});
};

const confirmPassword = (state, action) => {
    console.log("resetPassword")
    return updateObject(state, { 
        token: null,
        loading:false,
        redirect_path: "/en/confirmationpassword"});
};

const resetPasswordFail = (state, action) => {
    console.log("resetPassword")
    return updateObject(state, { 
        token: null,
        loading:false,
        redirect_path: null,
        error_reset_password: true});
};

const confirmPasswordFail = (state, action) => {
    console.log("resetPassword")
    return updateObject(state, { 
        token: null,
        loading:false,
        redirect_path: null,
        error_confirm_password: true
    });
};

const confirmLogin = (state, action) => {
    console.log("ACTION CONFIRMLOGINSUCCESS")
    return updateObject(state, { 
        logout: false
    });
};


const removeErrorState = (state, action) => {
    console.log("REMOVE ERROR STATE")
    return updateObject(state, { 
        error_code: null,
    });
};

const setAuthRedirectPath = (state, action) => {
    console.log(action.redirect_path)
    return updateObject(state, { redirect_path: action.redirect_path })
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_SIGNOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
        case actionTypes.RESET_PASSWORD: return resetPassword(state, action);
        case actionTypes.CONFIRM_PASSWORD: return confirmPassword(state, action);
        case actionTypes.RESET_PASSWORD_FAIL: return resetPasswordFail(state, action);
        case actionTypes.CONFIRM_PASSWORD_FAIL: return confirmPasswordFail(state, action);
        case actionTypes.CONFIRM_LOGIN: return confirmLogin(state, action);
        case actionTypes.REMOVE_ERROR_STATE: return removeErrorState(state, action);

        default:
    
    return state;
    }
};

export default reducer;