import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { signIn, removeAuthError } from '../redux/actions/index'
import { LOCAL_STORAGE_TOKEN } from '../shared/consts'
import { useDispatch, useSelector } from 'react-redux';


const Signin = props => {


    const { onSignIn, onRemoveErrorState, token, error } = props;


    useEffect(()=>{
        onRemoveErrorState()
    }, []) 


    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const clickHandler = (e) => {

        e.preventDefault()

        console.log(userName)
        console.log(password)

        onSignIn(userName, password)
    }
    console.log(props)


    return (
        <>  
            {
                localStorage.getItem(LOCAL_STORAGE_TOKEN) ? (
                    <Redirect to={{ pathname: props.lang === "EN" ? "/en" : "/de", state: { referer: props.location } }}
                    />
                ) : (   
                        <>
                        <div className="auth-wrapper align-self-center mt-5">
                            <div className="auth-inner signin">
                                <form>
                                    <h3 className="mb-2">{props.lang === "EN" ? "Sign in" : "Anmelden" }</h3>
                                    <p style={{marginBottom:"1px"}}>{props.lang === "EN" ? "Don't have an account yet?" : "Du hast noch keinen Account?" }</p>
                                    <Link to={props.lang === "EN" ? "/en/register" : "/de/register"}>{props.lang === "EN" ? "Sign up now" : "Hier registrieren" }</Link>
                                    <div className="form-group mt-4">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            value={userName}
                                            onChange={e => {
                                                setUserName(e.target.value);
                                            }}
                                            placeholder=""
                                        />
                                    </div>

                                    <div style={{marginBottom:"0px"}} className="form-group mt-4">
                                        <label>{props.lang === "EN" ? "Password" : "Passwort" }</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            value={password}
                                            onChange={e => {
                                                setPassword(e.target.value);
                                            }}
                                            placeholder=""
                                        />
                                    </div>
                                    {error && <p className="warning-label">{props.lang === "EN" ? "Wrong credentials, please try again!" : "Ungültige Anmeldedaten, bitte versuche es erneut!"}</p>}
                                    <button onClick={(e) => clickHandler(e)} className="btn btn-primary btn-block blue-btn mt-5">{props.lang === "EN" ? "Sign in" : "Anmelden" }</button>
                                    <p className="forgot-password text-left mt-4">
                                        <Link to={props.lang === "EN" ? "/en/resetpassword" : "/de/resetpassword"}>{props.lang === "EN" ? "Forgot password?" : "Passwort vergessen?" }</Link>
                                    </p>
                                </form>
                            </div>
                        </div>
                        </>
                )
            }           
        </>
    );
}

const mapStateToProps = state => {

    return {
        token: state.auth.token,
        error: state.auth.error_code,
        redirect_path: state.auth.redirect_path,
        question: state.question.question,
        id_of_answer: state.question.id_of_answer
    };
};

const mapDispatchToProps = dispatch => {

    return {
        onSignIn: (email, password) => dispatch(signIn(email, password)),
        onRemoveErrorState: () => dispatch(removeAuthError())
    };
};


export default 
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Signin)