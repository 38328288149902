import { connect } from 'react-redux';
import React, { useState } from "react";
import { signIn, validateAuthenticationAndGetData } from '../../redux/actions/index'


const Auth = props => {

    const { onSignIn } = props;


  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");


 const clickHandler = (e) => {

  e.preventDefault()

    console.log(userName)
    console.log(password)

    onSignIn(userName, password)
  }


  return (
      <div>
        <input
          type="username"
          value={userName}
          onChange={e => {
            setUserName(e.target.value);
          }}
          placeholder="email"
        />
        <br></br>
        <input
          type="password"
          value={password}
          onChange={e => {
            setPassword(e.target.value);
          }}
          placeholder="password"
        />
        <br></br>
        <button onClick={(e) => clickHandler(e)}>Sign In</button>
        <br></br>
      </div>
  );
}


const mapStateToProps = state => {

  return {
    token: state.auth.token,
    redirect_path: state.auth.redirect_path,
    question: state.question.question,
    id_of_answer: state.question.id_of_answer
  };
};

const mapDispatchToProps = dispatch => {

  return {
    onSignIn: (email, password) => dispatch(signIn(email, password)),
    onValidateAuthentication: () => dispatch(validateAuthenticationAndGetData()),

  };
};

export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Auth)