import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Container, Row, Col, ProgressBar } from 'react-bootstrap';
import {
    validateAuthenticationAndGetData,
    getNextQuestion,
    getPreviousQuestion,
    verifyAllQuestions
} from '../redux/actions/index'
import {Link, Redirect } from "react-router-dom";
import Result from './Result'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import back_arrow from '../assets/img/back_arrow.png'


const QuestionBeta = props => {

    const dispatch = useDispatch();

    //const { onSignIn, onNextQuestion, id_of_question } = props;

    const [id_of_answer, setAnswerID] = useState("");
    const [questionID, setQuestionID] = useState("");
    //const [passing, setPassing] = useState(null);


    const { question, id_of_question, loading, error, answers, token, result,first, last, redirectTo } = useSelector(
        state => ({
            error: state.auth.error_code,
            question: state.question.question.question,
            first: state.question.question.first,
            last:state.question.question.last,
            id_of_question: state.question.question._id,
            token: state.auth.token,
            loading: state.auth.loading,
            answers: state.question.question.answers,
            result: state.question.allowed_state,
            redirectTo: state.redirectTo
        })
    );

    const [country, selectCountry] = useState("");


    const changeHandler = (value) => {
        console.log(JSON.stringify(value._id))
        selectCountry(value)
    }

    //checking for the null value makes sure the first action runs once after first load

    useEffect(() => {
        if (question === null)
        dispatch(validateAuthenticationAndGetData());
    }, []);

    //setting the ID of the question. 

    useEffect(() => {
        setQuestionID(id_of_question);
    }, [question]);

    //checking if it is the last question response, coupled with no current question present. Then verify all questions. 

    useEffect(() => {       
        if((last === true)&&(!question))
        dispatch(verifyAllQuestions());
    }, [last, question]);

   //checking if allowed state has changed from null to false on the last question 


    const clickHandler = (id_of_question, answer) => {
        setAnswerID(answer._id)

        console.log(JSON.stringify(answer))

        if(answer.last){
            dispatch(verifyAllQuestions(id_of_question, answer._id));
        }else{
            dispatch(getNextQuestion(id_of_question, answer._id));      
        }
    }

    if (error) { return <div>Error! {error.message} </div>; }
    if (loading) { return <div>Loading...  <Link to={props.lang === "EN" ? "/en" : "/de"}>Home</Link></div>; }

    return (
        <>{  redirectTo && redirectTo != "/" ? (
            <Redirect to={{ pathname: redirectTo }} />
        ) : (
            <>
            {/* <ProgressBar now={30} />
            <p className="text-left p-4">6 von 15 beantwortet {result}</p> */}
            {first ? null : <Col xs={12} lg="3" className="back_arrow_container mr-lg-3 justify-content-md-center">
                <img className="back_arrow_img" onClick={() => dispatch(getPreviousQuestion())} src={back_arrow}></img>
                {/* <button  type="button" class="btn btn-outline-primary choice-button mb-4">
                    {props.lang === "EN" ? "Go Back" : "Zurück"}
                        </button> */}
            </Col>}



            <>
                            <Container className="mt-5">
                                <Row className="justify-content-md-center mb-4">
                                    <h5 className="question-title">
                                        {question && <p>{question}</p>}
                                    </h5>
                                                                        {/* Result component, based on allowed state */}
                                                                        <h5 className="question-title">
                                        { result !== null &&
                                            (() => {                    
                                                if (result === true || result === false)                                                              
                                                {
                                                    return <Redirect to={{
                                                        pathname: props.lang === "EN" ? `/en/result/?state=${result}` : `/de/result/?state=${result}`
                                                            }} />
                                                }                                                       

                                            })()
                                        }
                                    </h5>

                                </Row>



                                    <>{
                first ? 
                <>
                <div style={{maxWidth:"400px", marginRight: "auto", marginLeft: "auto"}} >
                <Select
                    
                    options={answers}
                    value={country}
                    onChange={(value) => changeHandler(value)}
                />
                </div>
                <br></br>
                <br></br>
                <button type="button" onClick={() => clickHandler(questionID, country)} class="btn btn-outline-primary choice-button mb-4">
                                                    {props.lang === "EN" ? "Confirm" : "Bestätigen"}
                </button>
                </> : 
                    <>
                                <Row className="justify-content-md-center pt-4">
                                    
                                    {
                                        answers && answers !==null && answers.map((answer, index) => {
                                            return (<Col xs={12} lg="3" className="mr-lg-3">
                                                <button type="button" onClick={() => clickHandler(questionID, answer)} class="btn btn-outline-primary choice-button mb-4">
                                                    {answer.text}
                                                </button>
                                            </Col>
                                            )
                                        })
                                    }
                                
                                </Row>
                    </>
            
            }
            </>


                            </Container>
                </>
            </>
        )
        }
           
        </>
    );
}

export default QuestionBeta