import './App.css';
import { connect } from 'react-redux';
import Layout from './hoc/Layout'
import Navigation from './hoc/Navigation'
import BottomNavigation from './hoc/BottomNavigation/BottomNavigation'
import React, {useEffect, Suspense} from 'react';
import AllRoutes from './routing/AllRoutes'
import { validateAuthenticationAndGetData, getNextQuestion } from './redux/actions/index'

let windowHeight = null;

const App = props => {


  useEffect(() => {
    windowHeight = (window.screen.height-200) + "px"

    console.log("windowHeight => " + windowHeight)

  })


  return (
      <div style={{height:windowHeight, marginTop:"140px"}} className="App">
        <Navigation />
        <Layout>
              <Suspense><AllRoutes/></Suspense>
        </Layout>
        <BottomNavigation></BottomNavigation>
    </div>
  );
}

const mapStateToProps = state => {

  return {
    token: state.auth.token,
    redirect_path: state.auth.redirect_path,
    question: state.question.question,
    id_of_answer: state.question.id_of_answer
  };
};

const mapDispatchToProps = dispatch => {

  return {
    onSignIn: () => dispatch(getNextQuestion("5f69aa9d8ad60b00172ad048")),
    onValidateAuthentication: () => dispatch(validateAuthenticationAndGetData()),

  };
};

export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)