import React, { useState,  }  from 'react';
import { connect, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { sendResetPasswordMail } from '../redux/actions/index'


const ResetPassword = props => {

    const { onSendResetPassword, token, error_reset_password, redirectTo } = props;

    const [userEmail, setUserEmail] = useState("");

    const clickHandler = (e) => {

        e.preventDefault()
        console.log(userEmail)
        onSendResetPassword(userEmail)
    }
  

    return (


        <>{  redirectTo && redirectTo != "/" ? (
            <Redirect to={{ pathname: redirectTo }} />
        ) : (
            <>
            <div className="auth-wrapper align-self-center mt-5">
                <div className="auth-inner reset-password">
                    <form>
                        <h3 className="mb-4">{props.lang === "EN" ? "Reset Password" : "Passwort zurücksetzen"}</h3>
                        <p>{props.lang === "EN" ? "Enter your email for a password reset link." : "Bitte gebe deine E-Mail ein, um den Passwort-Zurücksetzen-Link zu erhalten."}</p>
                      
                        <div className="form-group mt-4">
                            <label>Email</label>
                            <input
                                type="email"
                                className="form-control"
                                value={userEmail}
                                onChange={e => {
                                    setUserEmail(e.target.value);
                                }}
                                placeholder=""
                            />
                        </div>
                        {error_reset_password && <p className="warning-label">{props.lang === "EN" ? "Invalid E-Mail, please try again!" : "Ungültige E-Mail Adresse, bitte versuche es erneut!"}</p>}

                        <button onClick={(e) => clickHandler(e)} type="submit" className="btn btn-primary btn-block blue-btn mt-4">{props.lang === "EN" ? "Send Reset Link" : "Bestätigen"}</button>
                        <p className="forgot-password text-left mt-4">
                            <Link to={props.lang === "EN" ? "/en/signin" : "/de/signin"}>{props.lang === "EN" ? "Back to Sign in" : "Zurück zum Anmelden"}</Link>
                        </p>
                    </form>
                </div>
            </div>
        </>
        )}</>
       
    );
} 

const mapStateToProps = state => {

    console.log(state.auth.redirect_path)

    return {
        token: state.auth.token,
        error: state.auth.error_code,
        redirectTo: state.auth.redirect_path,
        question: state.question.question,
        error_reset_password: state.auth.error_reset_password
    };
};

const mapDispatchToProps = dispatch => {

    return {
        onSendResetPassword: (email) => dispatch(sendResetPasswordMail(email)),
    };
};

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ResetPassword)