import React from "react";
import { Route, Redirect } from "react-router-dom";
import {LOCAL_STORAGE_TOKEN} from '../shared/consts'

function PrivateRoute({ component: Component, ...rest }) {

  return (
    <Route
      {...rest}
          render={props =>
       localStorage.getItem(LOCAL_STORAGE_TOKEN) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/en/signin", state: { referer: props.location } }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;